<template>
  <div>
    <div class="container">
      <div class="left">
        <el-input type="textarea" v-model="input" :rows="rows"></el-input>
      </div>
      <div class="middle" v-bind:style="{ height: height + 'px' }">
        <el-button
          v-bind:style="{ marginTop: height / 2 - 100 + 'px' }"
          type="primary"
          @click="textToUnicode"
          size="small"
          plain
          >>> Text to Unicode >></el-button
        >
        <br />
        <br />
        <el-button type="primary" size="small" plain @click="unicodeToText"
          >>> Unicode to Text >></el-button
        >
      </div>
      <div class="right">
        <el-input type="textarea" v-model="output" :rows="rows"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Transcode",
  data() {
    return {
      input: "",
      output: "",
      rows: 20,
      height: 200,
    };
  },
  mounted() {
    this.rows = window.innerHeight / 30;
    this.height = window.innerHeight - 300;
  },
  methods: {
    unicodeToText() {
      this.output = unescape(this.input.replace(/\\u/g, "%u"));
    },
    textToUnicode() {
      var str = "";
      for (var i = 0; i < this.input.length; i++) {
        str += this.toUnicode(this.input[i]);
      }
      this.output = str;
    },
    // 汉字转为Unicode字符码表示
    toUnicode(s) {
      return s.replace(/([\u4E00-\u9FA5]|[\uFE30-\uFFA0])/g, function() {
        return "\\u" + RegExp["$1"].charCodeAt(0).toString(16);
      });
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  text-align: center;
}
.left {
  width: 42.5%;
  float: left;
  background: red;
}
.right {
  width: 42.5%;
  float: right;
  background: chartreuse;
}
.middle {
  width: 15%;
  float: left;
  text-align: center;
}
</style>
